.rcp-root {
  --rcp-background-color: #ffffff;
  --rcp-field-input-color: #3e3e3e;
  --rcp-field-input-border-color: #e5e5e5;
  --rcp-field-label-color: #8e8e8e;
}
.rcp {
  border-radius: 0;

  .rcp-saturation {
    border-radius: 15px;
  }

  .rcp-field-input {
    transition: all 0.35s ease-out;

    &:focus {
      @apply border-primary-500;
    }
  }

  .rcp-body {
    padding-bottom: 0;
  }

  // .rcp-field-label{
  //   @apply text-dark;
  // }
}
