@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/fh-oscar/woff/FHOscar-Black.woff') format('woff'), 
    url('/fonts/fh-oscar/ttf/FHOscar-Black.woff') format('truetype'),
    url('/fonts/fh-oscar/otf/FHOscar-Black.woff') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/fh-oscar/woff/FHOscar-Bold.woff') format('woff'), 
    url('/fonts/fh-oscar/ttf/FHOscar-Bold.woff') format('truetype'),
    url('/fonts/fh-oscar/otf/FHOscar-Bold.woff') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/fh-oscar/woff/FHOscar-Medium.woff') format('woff'), 
    url('/fonts/fh-oscar/ttf/FHOscar-Medium.woff') format('truetype'),
    url('/fonts/fh-oscar/otf/FHOscar-Medium.woff') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'FH Oscar';
  src: url('/fonts/fh-oscar/woff/FHOscar-Light.woff') format('woff'), 
    url('/fonts/fh-oscar/ttf/FHOscar-Light.woff') format('truetype'),
    url('/fonts/fh-oscar/otf/FHOscar-Light.woff') format('opentype');
  font-weight: 300;
  font-style: normal;
}