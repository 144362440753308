.input-field {
  transition: 0.3s ease-out all;
  appearance: none;
  -webkit-appearance: none;

  & > label {
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) transform;
  }

  & ~ label {
    margin-left: 16px;
    transition: 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) transform;

    @include sm {
      margin-left: 20px;
    }

    &.has-prefix {
      margin-left: 34px;

      @include sm {
        margin-left: 34px;
      }
    }
  }

  &:not(button):not(:placeholder-shown),
  &:not(button):focus {
    background: #fff;
    border-color: rgba($color-grey-border, 0.6);

    & ~ label {
      padding: 3px 5px;
      background: #fff;
      top: 0;
      transform: translateY(-50%);
      font-size: 12px;
      line-height: 1;

      &.has-prefix {
        margin-left: 16px;

        @include sm {
          margin-left: 20px;
        }
      }
    }
  }

  &:focus {
    border-color: $color-primary !important;
  }

  &[data-has-error="true"] {
    border-color: $color-danger;
  }
}

// input {
//   font-weight: inherit;
// }

/* Change Autocomplete styles in Chrome*/
// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   // border: 1px solid green;
//   // -webkit-text-fill-color: green;
//   -webkit-box-shadow: 0 0 0px 1000px #fff inset;
//   transition: background-color 5000s ease-in-out 0s;
// }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 3000px #fff inset !important;
  box-shadow: initial;
  // color: #55586f;
  -webkit-text-fill-color: #3e3e3e;
  transition: background-color 5000s ease-in-out 0s;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[data-has-error="true"],
select[data-has-error="true"],
textarea[data-has-error="true"] {
  border-color: #bf0637 !important;
}

input:not([type="checkbox"]) {
  -webkit-appearance: none;
}

input:checked + label div {
  @apply border-accent-green-500;
}
input:checked + label div svg {
  @apply block;
}
