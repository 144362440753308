.bg-purple-gradient {
  background-image: linear-gradient(139.06deg, #332098 0%, #332098 0.01%, #5943dd 100%);
}

.fancy-circles-bg {
  position: relative;
  overflow: hidden;
  --bg-color: rgba(255, 255, 255, 0.2);

  &--dark {
    --bg-color: rgba(142, 142, 142, 0.3);
  }

  & > * {
    position: relative;
    z-index: 50;
  }

  &::after {
    position: absolute;
    content: "";
    width: 100%;
    padding-top: 100%;
    border-radius: 50%;
    border: 1px solid var(--bg-color);
    // border-color: var(--bg-color);
    right: 30%;
    top: -20%;
    z-index: 0;
  }

  &::before {
    position: absolute;
    content: "";
    width: 80%;
    padding-top: 80%;
    border-radius: 50%;
    border: solid 1px var(--bg-color);
    right: 30%;
    top: -20%;
    z-index: 0;
  }
}

.is-disabled {
  cursor: not-allowed;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(#fff, 0.85);
    z-index: 10;
  }
}

.words-line {
  position: relative;
  overflow: hidden;
}

.no-outline {
  outline: none;

  &:focus {
    outline: none;
  }
}

.card-shadow {
  filter: drop-shadow(0 0 5px 25px rgba(0, 0, 0, 0.05));
  // filter: drop-shadow(0 10px 30px #333);
}
.elevation-shadow {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}

.linear-gradient {
  &-accent {
    background: linear-gradient(139.06deg, #f79b01 0%, #d48706 10%, #f79b01 100%);
  }

  &-primary {
    background: linear-gradient(139.06deg, #332098 0%, #332098 10%, #5943dd 100%);
  }

  &-danger {
    background: linear-gradient(139.06deg, #dd2e44 0%, #e22c43 10%, #ff6767 100%);
  }

  &-success {
    background: linear-gradient(139.06deg, #72cf90 0%, #128d3a 10%, #66c670 100%);
  }
}

.plan-card-with-pattern {
  background: url("/images/plan-pattern.png");
  background-size: cover;
}

.blur-bg {
  backdrop-filter: blur(8px);
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.blur-3 {
  backdrop-filter: blur(3px);
}

.blur-5 {
  backdrop-filter: blur(5px);
}

.blur-8 {
  backdrop-filter: blur(8px);
}

.blur-10 {
  backdrop-filter: blur(10px);
}

.responsive-wrapped-heading-text {
  font-size: clamp(28px, 4.5vw, 42px) !important;
}

.responsive-wrapped-heading-two {
  font-size: clamp(20px, 3.2vw, 32px) !important;
}

.responsive-wrapped-sub-heading-text {
  font-size: clamp(18px, 2.4vw, 30px);
}

.responsive-wrapped-body-text {
  font-size: clamp(20px, 2vw, 24px);
}
