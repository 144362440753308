.rdrCalendarWrapper {
  width: 100%;
  // max-width: 450px;
  // margin: 0 auto;
  // display: block;

  .rdrMonthAndYearWrapper {
    padding-top: 0;
    margin-bottom: 10px;
    height: 45px;
  }

  .rdrMonth {
    width: 100%;
    padding: 0 10px;
  }

  .rdrWeekDays {
    background: #fafafa;
    margin-bottom: 15px;
    margin-top: 5px;
    border-radius: 8px;

    .rdrWeekDay {
      line-height: unset;
      padding: 12px 0;
      font-size: 14px;
      color: #747478;
    }
  }

  .rdrDays {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
  }

  // .rdrDays {
  //   display: grid;
  //   width: 100%;
  //   grid-template-columns: repeat(7, 36px);
  //   column-gap: calc((100% - (36px * 7)) / 6);
  // }
}

.rdrDayNumber {
  font-size: 14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;

  span {
    color: #656565;
    font-weight: 500;
    font-family: "Inter";

    // &:after {
    //   background-color: #332098 !important;
    //   bottom: -3px !important;
    // }

    &::after {
      display: none !important;
    }
  }
}

.rdrSelected {
  border-radius: 50px;
  left: 0;
  right: 0;
}

.rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span {
  color: #656565;
}

.rdrDayPassive .rdrDayNumber span {
  color: #899199;
  font-weight: 400;
}

.rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
.rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span {
  color: #fff;
}

.rdrNextPrevButton {
  height: 30px;
  width: 30px;
  border-radius: 999px;
  background: #f8f8f8 !important;
  border: none;
  transition-duration: 150ms;

  i {
    border: 1.5px solid #332089;
    margin: 0px 0px 0px 10px;
    border-width: 0 1.5px 1.5px 0;
    display: block;
    padding: 3px;
    transform: rotate(-45deg);
  }

  &.rdrPprevButton i {
    transform: rotate(135deg);
    margin: 0px 0px 0px 13px;
  }

  &:hover,
  &:hover i {
    border-color: #332098;
  }
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
  height: 100%;
  top: 0;
}

.rdrDay:not(.rdrDayStartOfMonth):not(.rdrDayEndOfMonth) {
  .rdrInRange {
    &::after,
    &::before {
      content: "";
      height: 100%;
      width: 50%;
      background: #f8f8f8;
      position: absolute;
    }

    &::before {
      right: 100%;
    }

    &::after {
      left: 100%;
    }
  }
}

.rdrDay.rdrDayStartOfWeek {
  .rdrInRange {
    &::before {
      display: none;
    }
  }
}

.rdrDay.rdrDayEndOfWeek {
  .rdrInRange {
    &::after {
      display: none;
    }
  }
}

.rdrInRange {
  color: #f8f8f8 !important;
}

// .rdrDayNumber {
// }
// .rdrNextPrevButton:hover,
// .rdrNextPrevButton:hover i {
// }

.rdrMonthAndYearPickers {
  font-size: 16px;
  font-family: "FH Oscar";

  select {
    padding: 10px 20px 10px 15px;
    border-radius: 10px;
  }

  select:hover {
    background-color: #f8f8f8;
  }
}

.rdrDay {
  margin: 5px 0;
  height: 38px;

  @include sm {
    height: 40px;
  }
}

.rdrEndEdge {
  z-index: 10;
  border-radius: 50px;
  right: 0;
}

.rdrStartEdge {
  z-index: 10;
  border-radius: 50px;
  left: 0;
}

.rdrDayStartOfMonth .rdrInRange,
.rdrDayStartOfMonth .rdrEndEdge,
.rdrDayStartOfWeek .rdrInRange,
.rdrDayStartOfWeek .rdrEndEdge {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  left: 0;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  right: 0;
}

.rdrSelected {
  color: #332098 !important;
}

.rdrCalendarWrapper .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
  color: white !important;
}

.rdrDayInPreview,
.rdrDayEndPreview,
.rdrDayStartPreview {
  height: 100%;
  top: 0;
  // top: 50%;
  // transform: translateY(-50%);
}

.rdrDayStartOfMonth .rdrDayInPreview,
.rdrDayStartOfMonth .rdrDayEndPreview,
.rdrDayStartOfWeek .rdrDayInPreview,
.rdrDayStartOfWeek .rdrDayEndPreview {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.rdrDayEndOfMonth .rdrDayInPreview,
.rdrDayEndOfMonth .rdrDayStartPreview,
.rdrDayEndOfWeek .rdrDayInPreview,
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.rdrDayStartPreview {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.rdrDayEndPreview {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber:after {
  display: none;
}

.rdrDayToday .rdrDayNumber span {
  color: #332089;
}
