.spinner {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 3.5px solid rgba(#d9d9d9, 0.5);
  border-top-color: currentColor;
  animation: 1.5s spin infinite linear;

  &--sm {
    width: 15px;
    height: 15px;
    border-width: 2px;
  }

  &--lg {
    width: 70px;
    height: 70px;
    border-width: 5px;
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

$spinner-size: 75px !default;
$spinner-color: #fff !default;

.spinner-x {
  font-size: $spinner-size;
  position: relative;
  display: inline-block;
  width: 1em;
  height: 1em;

  font-size: 30px;
  @include sm {
    font-size: 40px;
  }

  &.center {
    @include absolute-center;
  }
}

.spinner-blade {
  position: absolute;
  left: 0.4629em;
  bottom: 0;
  width: 0.074em;
  height: 0.2777em;
  border-radius: 0.5em;
  background-color: transparent;
  transform-origin: center -0.2222em;
  animation: spinner-fade 1s infinite linear;

  $animation-delay: 0s;
  $blade-rotation: 0deg;

  @for $i from 1 through 12 {
    &:nth-child(#{$i}) {
      animation-delay: $animation-delay;
      transform: rotate($blade-rotation);
      $blade-rotation: $blade-rotation + 30;
      $animation-delay: $animation-delay + 0.083;
    }
  }
}

@keyframes spinner-fade {
  0% {
    background-color: $spinner-color;
  }

  100% {
    background-color: transparent;
  }
}
