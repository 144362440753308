@keyframes scale-up {
  33% {
    transform: scale(1.2);
  }
  66% {
    transform: scale(0.8);
  }
}

.anim-scale-up {
  animation: scale-up forwards 0.8s ease-out;
}

@keyframes word-up {
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.anim-word-up {
  --delay: 0s;
  opacity: 0;
  transform: translateY(100%);
  position: relative;
  animation: word-up 0.5s var(--delay) ease-out forwards;
}

@keyframes fade-out {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.anim-fade-up {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.7s var(--delay) cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
}

.anim-fade-up-fast {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.3s var(--delay) cubic-bezier(0.755, 0.05, 0.855, 0.06) forwards;
}

.anim-fade-in {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.3s var(--delay) cubic-bezier(0.755, 0.05, 0.855, 0.06) reverse;
}

.anim-fade-in-fast {
  --delay: 0s;
  opacity: 0;
  animation: fade-out 0.5s var(--delay) cubic-bezier(0.755, 0.05, 0.855, 0.06) reverse;
}

@keyframes auth-display-image-side {
  0% {
    opacity: 0;
    transform: translateY(20%);
  }
  50% {
    opacity: 1;
    transform: translateY(0);
  }
  60% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0) rotate(var(--rotate-deg));
  }
}

@keyframes auth-display-image-center {
  to {
    opacity: 1;
    transform: translate(-50%, -15%);
  }
}

.auth-display-image {
  --rotate-deg: -10deg;
  opacity: 0;
  transform: translateY(20%);
  animation: auth-display-image-side 2s 1s forwards ease-out;
}

.auth-display-image--3 {
  --rotate-deg: 10deg !important;
}

.auth-display-image--2 {
  opacity: 0;
  transform: translate(-50%, 20%);
  animation: auth-display-image-center 1s 1s forwards ease-out;
}

@keyframes draw-mark {
  to {
    stroke-dashoffset: 0;
  }
}

.anim-draw-checkmark {
  stroke-dasharray: 50;
  stroke-dashoffset: 50;
  animation: draw-mark 1.2s 0.3s ease-out forwards;
}

@keyframes x-pulse {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.anim-pulse {
  transform: scale(0.8);
  animation: x-pulse 0.5s cubic-bezier(0.215, 0.61, 0.355, 1) forwards;
}

@keyframes modal-wrapper {
  100% {
    opacity: 1;
    // pointer-events: auto;
  }
}

@keyframes modal-wrapper-rev {
  100% {
    opacity: 0;
    // pointer-events: auto;
  }
}

.modal-full-screen-anim {
  opacity: 0;
  transform: translateY(10%) scale(0.95);
  animation: modal-full-screen 0.3s forwards ease-out;
}

.modal-full-screen-anim-rev {
  opacity: 1;
  transform: translateY(0) scale(1);
  animation: modal-full-screen-rev 0.3s forwards ease-out;
}

@keyframes modal-full-screen {
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
    // pointer-events: auto;
  }
}

@keyframes modal-full-screen-rev {
  100% {
    opacity: 0;
    transform: translateY(10%) scale(0.95);
    // pointer-events: auto;
  }
}

.modal-wrapper-anim {
  opacity: 0;
  animation: modal-wrapper 0.3s forwards ease-in-out;
}

.modal-wrapper-anim-rev {
  opacity: 1;
  animation: modal-wrapper-rev 0.3s forwards ease-in-out;
}

@keyframes modal-body {
  100% {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}

@keyframes modal-body-rev {
  100% {
    opacity: 0.7;
    transform: scale(1) translateY(40px);
    @include sm {
      transform: scale(0.9) translateY(40px);
    }
  }
}

.modal-body-anim {
  // show ? "opacity-100 sm:scale-100 translate-y-0" : "opacity-70 sm:scale-90 translate-y-10"
  opacity: 0.7;
  transform: scale(1) translateY(40px);
  @include sm {
    transform: scale(0.9) translateY(40px);
  }
  animation: modal-body 0.5s forwards ease-in-out;
}

.modal-body-anim-rev {
  opacity: 1;
  transform: scale(1) translateY(0);
  animation: modal-body-rev 0.5s forwards ease-in-out;
}

/* animation */

@keyframes slide {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(-50%, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-52%, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(-46%, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-58%, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(-42%, 0, 0);
  }
}

$overlay-color: black !default;

@mixin absolute-center {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.overlay {
  background: rgba($overlay-color, 0.9);
  @include absolute-center;
}

.selfie {
  &-borders {
    stroke-dasharray: 70;
    stroke-dashoffset: 70;
    animation: draw-mark 0.8s 0.2s ease-in-out forwards;
  }

  &-borders-rev {
    stroke-dasharray: 70;
    stroke-dashoffset: -70;
    animation: draw-mark 0.8s 0.2s ease-in-out forwards;
  }

  &-eyes {
    stroke-dasharray: 15;
    stroke-dashoffset: 15;
    animation: draw-mark 0.4s 1s ease-in-out forwards;
  }

  &-nose {
    stroke-dasharray: 35;
    stroke-dashoffset: 35;
    animation: draw-mark 0.8s 1.4s ease-in-out forwards;
  }

  &-mouth {
    stroke-dasharray: 40;
    stroke-dashoffset: -40;
    animation: draw-mark 0.8s 2.4s ease-in-out forwards;
  }
}

.nav-item-active-icon {
  transform: translateX(-100%);
  animation: show-active-icon 0.5s ease-in-out forwards;
}

.nav-item-inactive-icon {
  transform: translateX();
  animation: show-active-icon 0.5s ease-in-out reverse;
}

@keyframes show-active-icon {
  100% {
    transform: translateX(0);
  }
}

.hideable {
  overflow: hidden;
  max-height: 1000px;
  transition: ease-out 0.5s all;
}

.hideable.hide {
  max-height: 0;
}

.dissapear {
  animation: dissapear 0.5s ease-out forwards;
  overflow: hidden;
}

.reappear {
  animation: dissapear reverse 0.5s ease-out forwards;
  max-height: 0;
  overflow: hidden;
}

@keyframes dissapear {
  0% {
    max-height: 1000px;
  }

  100% {
    max-height: 0;
  }
}

.transfer-waiting-loader {
  // animation-timing-function: transfer-loader;
  transform: translateX(-100%);
  animation-name: transfer-loader;
  animation-duration: 3s;
  animation-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
  animation-iteration-count: infinite;
}

@keyframes transfer-loader {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(400%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.ripple {
  cursor: pointer;
  background:  
    radial-gradient(circle, #0000 1%, var(--c) 1%) 50%/0%
    var(--c);
  transition: background 0.6s;
}
.ripple:hover,
.ripple:focus-visible{
  background-size: 15000%;
}
.ripple:active {
  background-color: var(--r,#ffffff35); /* color of the ripple effect*/
  background-size: 100%;
  transition: 0s;
}
.ripple:focus-visible {
  outline-offset: 2px;
  outline: 3px solid #000;
}

.light {
  --c: #ffff;
  color: #ffffff;
}
.dark {
  --c: #ffffff;
  color: #ffff;
  border-color: var(--c);
}