@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  /* Text classes */
  .body-padding {
    @apply px-6 sm:px-10 lg:px-20;
  }

  .container{
    @apply max-w-[1200px] mx-auto;
  }
}